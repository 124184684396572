import React,{useEffect} from 'react';
import image from '../../Images/Pages/about1.jpg';
import about2 from '../../Images/Pages/about-2.jpg';
import logo from '../../Images/Logo/logo.png'
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';

function About () {

useEffect(()=>{
    new wow.WOW().init();
},[]);

  return (
    <>
    <Layout>
      
    <img src={image}/>
      <div className='cities-top'>
        <div className="container">
        <h3>BOUT US</h3>
        <h4>RABIAN HOSPITALITY IS WORLD-RENOWNED BUT RARELY DEFINED.</h4>
        <p>Our traditional hospitality differs from other cultures in that it expects nothing in return.</p>
       
      </div>
      </div>
      <section className='about-us-section mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7 wow animate__animated animate__slideInLeft' data-wow-duration="1s" data-wow-delay="0.2s">
              <h1>About Prestige </h1>
              <img src={logo} alt='' />
              <p>
              Prestige hotel portfolio is popular among major tour operators due to its strategic location near major landmarks, as well as its high-end services tailored to meet the needs of travelers seeking authentic Arabian hospitality.
              </p>
            </div>
            <div className='col-sm-5 wow animate__animated animate__slideInRight' data-wow-duration="1s" data-wow-delay="0.2s">
              <img src={about2} alt='' />
            </div>
          </div>
         
        </div>
      </section>
      </Layout>
    </>
  )
}

export default About
