import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/Style.css';
import './Style/Mediaquries.css'
import 'wowjs/css/libs/animate.css';
import 'animate.css';
 import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
 import Home from './Pages/Home/Home';
import Contact from './Pages/Contactus/Contact';
import About from './Pages/Aboutus/Aboutus';

import Hotels from './Pages/Hotels/Hotel';
import HotelDetail from './Pages/Hotels/HotelDetail';
import BookRoom from './Pages/BookRoom/BookRoom';

import Confirmation from './Pages/BookingConfirmation/HotelInvoice';

import FaqPage from './Pages/FooterPages/faqPage';
import PrivacyPolicy from './Pages/FooterPages/PrivacyPolicyPage';
import TermsConditions from './Pages/FooterPages/TermsConditions';
import ComplaintPolicy from './Pages/FooterPages/ComplaintPolicy';
import Medina from './Pages/Cities/medinah';
import Makkah from './Pages/Cities/makkah';

function App() {
  return (
   <>
   <Router>
    <Routes>
    
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/about-us" element={<About />} />
      {/* <Route path="/umrah-packages" element={<Umrahpackages />} /> */}
      <Route path="/hotels" element={<Hotels />} />
      <Route path="/hotel_detail/:id" element={<HotelDetail />} />
      <Route path="/hotel_checkout" element={<BookRoom />} />
      <Route path="/faqs" element={<FaqPage />} />
      <Route path="/complaint_policy" element={<ComplaintPolicy/>} />
      <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
      <Route path="/terms_and_conditions" element={<TermsConditions/>} />
      <Route path="/hotel_booking_invoice/:id" element={<Confirmation />} />
      <Route path="/makkah" element={<Makkah />} />
      <Route path="/medina" element={<Medina />} />
    </Routes>
   
    </Router>
   
   </>
  );
}

export default App;
