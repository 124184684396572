import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import logo from '../../Images/logo.png'
import { ToastContainer, toast } from 'react-toastify'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import Loading from '../../Components/Loading/Loader'
function HotelInvoice () {
  const { id } = useParams()
  var endpoint = ApiEndPoint()
  const todayDate = new Date()
  const [invoiceDetail, setInvoiceDetail] = useState([])
  const [hotelDetail, setHotelDetail] = useState([])
  const [invoiceNo, setInvoiceNo] = useState('')
  const [hotel, setHotel] = useState([])
  const [hoteldetail2, setHoteldetail2] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [rooms, setRooms] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [leadPassenger, setLeadPassenger] = useState([])
  const [otherPassenger, setOtherPassenger] = useState([])
  const [roomPriceSums, setRoomPriceSums] = useState([])
  const finalRoomPrices = []

  useEffect(() => {
    getinvoicedetail()
  }, [])

  useEffect(() => {
    total()
  }, [rooms])
  let Checkin = moment(hoteldetail2.checkIn)
  let checkout = moment(hoteldetail2.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const getinvoicedetail = async () => {
    setIsLoading(true)
    var invoiceno = {
      // invoice_no: 'AHT3250517'
        invoice_no: id
    }
    // _Live
    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/view/reservation_Live',
        invoiceno,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response.length !== '') {
        setIsLoading(false)
        var data = response.data.booking_data
        setInvoiceNo(data.invoice_no)
        var lead = JSON.parse(data.lead_passenger_data)
        var other = JSON.parse(data.other_adults_data)
        setOtherPassenger(other)
        setLeadPassenger(lead)
        var hoteldata = JSON.parse(data.reservation_response)
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        )
        setHoteldetail2(hoteldata.hotel_details)
        setRooms(hoteldata.hotel_details.rooms)
        setHotel(hoteldata)
        setInvoiceDetail(response.data.booking_data)
      }
      setIsLoading(false)
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const printinvoice = () => {
    window.print()
  }
  const total = () => {
    let roomsprice = 0
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = 0
    var length = rooms.length
    for (var x = 0; x < length; x++) {
      roomsprice = Number(rooms[x].room_rates[0].net)
      finalpricemarkup = roomsprice
      if (Number(hotel.admin_markup) !== 0) {
        if (hotel.admin_markup_type === 'Percentage') {
          markupprice = (roomsprice * Number(hotel.admin_markup)) / 100
        } else {
          markupprice = Number(hotel.admin_markup)
        }
        // adminmarkupprice=markupprice;
        finalpricemarkup += markupprice
      }
      if (Number(hotel.customer_markup) !== 0) {
        if (hotel.customer_markup_type === 'Percentage') {
          markupprice = (finalpricemarkup * Number(hotel.customer_markup)) / 100
        } else {
          markupprice = Number(hotel.customer_markup)
        }
        //  clientmarkupprice=markupprice;
        finalpricemarkup += markupprice
      }
      finalRoomPrices.push(finalpricemarkup)
      setRoomPriceSums(finalRoomPrices)
    }
  }
  const calculatemarkup = price => {
    let roomsprice = Number(price)
    let markupprice = 0
    let finalpricemarkup = Number(price)

    if (Number(hotel.admin_markup) !== 0) {
      if (hotel.admin_markup_type === 'Percentage') {
        markupprice = (roomsprice * Number(hotel.admin_markup)) / 100
      } else {
        markupprice = Number(hotel.admin_markup)
      }
      // adminmarkupprice=markupprice;
      finalpricemarkup += markupprice
    }
    if (Number(hotel.customer_markup) !== 0) {
      if (hotel.customer_markup_type === 'Percentage') {
        markupprice = (finalpricemarkup * Number(hotel.customer_markup)) / 100
      } else {
        markupprice = Number(hotel.customer_markup)
      }
      //  clientmarkupprice=markupprice;
      finalpricemarkup += markupprice
    }
    return finalpricemarkup.toFixed(2)
  }
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const BookingCancillation = async () => {
    var apitoken = Hotelapitoken()
    setModalOpen(!modalOpen)
    var data = {
      token: apitoken,
      invoice_no: invoiceNo
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/hotel/reservation/cancell/new_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      var data = JSON.parse(response.data.view_reservation_details)
      if (data.error) {
        setIsLoading(false)
        toast.info(data.error.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        getinvoicedetail()
        toast.info('Your reservation has been cancelled', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  return (
    <>
    {isLoading && (
      <Loading/>
    )}
    <ToastContainer/>
    <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Confirmation 
        </ModalHeader>
        <ModalBody>
        <div className='form-group'>
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </ModalBody>
        <ModalFooter>
        <button onClick={toggleModal} className='btn btn-secondary' >
            Close
          </button>
          <button
          onClick={BookingCancillation}
            className=' btn btn-danger '
          >
            Confirm
          </button>
        </ModalFooter>
        </Modal>
      <div class='container mt-4' style={{ color: '#000' }}>
        <div class='row mb-4'>
          <div class='col-md-2 col-lg-2 col-sm-2 text-center'>
            <img src={logo} height='140' />
          </div>
          <div class='col-md-8 col-sm-8 col-lg-8 text-center'>
            <h2>Prestige Hotels</h2>
            <h3>(jeddah)</h3>
            <p>4271 Zaid Bin Al Khattab, An Naim Dist. 23622 Jeddah, KSA</p>
            <p>Phone: 123 456 789</p>
            <p>Email: info@prestige.com</p>
          </div>
          <div class='col-md-2 col-lg-2 col-sm-2 text-end'>
            {/* <p>WAQAR45-BAKER</p>
            <p>SALEM</p> */}
          </div>
          <div className='col-lg-8 col-md-7 col-sm-7 mt-2'>
            {invoiceDetail.status==='CONFIRMED' ? (
              <h6 class='mb-3'>
                Invoice No: {invoiceNo} | Status: <span style={{color:'#63ab45'}}>{invoiceDetail.status}</span>
              </h6>
            ):(
              <h6 class='mb-3'>
                Invoice No#: {invoiceNo} | Status: {invoiceDetail.status}
              </h6>
            )}
          </div>
          <div className='col-lg-4 col-md-5 col-sm-5 text-end mt-2'>
            <h6>
              DATE CREATED:{' '}
              {moment(invoiceDetail.creationDate).format('DD-MM-YYYY')}
            </h6>
          </div>
        </div>
        <div class='general-info mt-3'>
          <h6>GENERAL INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Total Pilgrims</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Total Room</th>
                  <th>Check in Date</th>
                  <th>Check out Date</th>
                  <th>Total Nights</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {Number(invoiceDetail.total_adults) +
                      Number(invoiceDetail.total_childs)}
                  </td>
                  <td>{invoiceDetail.total_adults}</td>
                  <td>{invoiceDetail.total_childs}</td>
                  <td>{invoiceDetail.total_rooms}</td>
                  <td>{moment(hoteldetail2.checkIn).format('DD-MM-YYYY')}</td>
                  <td> {moment(hoteldetail2.checkOut).format('DD-MM-YYYY')}</td>
                  <td>{daysBetween}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class='accommodation-details mt-3'>
          <h6>HOTEL DETAILS</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>City</th>
                  <th>Hotel</th>
                  <th>Category</th>
                  <th>Price:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {hoteldetail2.destinationName}</td>
                  <td>{hoteldetail2.hotel_name}</td>
                  <td>{hoteldetail2.stars_rating} Stars</td>
                  <td>
                    {invoiceDetail.selected_currency}{' '}
                    {invoiceDetail.status == 'Cancelled'
                      ? '0'
                      : invoiceDetail.exchange_price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class='accommodation-details mt-3'>
          <h6>ROOM DETAILS</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Board Type</th>
                  <th>Room Quantity</th>
                  <th>Passenger</th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td> {item.room_name}</td>
                    <td>{item.room_code}</td>
                    <td> {item.room_rates[0].room_board}</td>
                    <td>{item.room_rates[0].room_qty}</td>
                    <td>
                      {' '}
                      {item.room_rates[0].adults} Adults,{' '}
                      {item.room_rates[0].children} Children
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div class='accommodation-details mt-3'>
          <h6>PASSENGERS' INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Pilgrims Name</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lead Passenger</td>
                  <td>
                    {leadPassenger.lead_title +
                      ' ' +
                      leadPassenger.lead_first_name +
                      ' ' +
                      leadPassenger.lead_last_name}
                  </td>
                  <td>{leadPassenger.lead_email}</td>
                  <td>
                    {moment(leadPassenger.lead_date_of_birth).format(
                      'DD-MM-YYYY'
                    )}
                  </td>
                  <td>{leadPassenger.lead_country} </td>
                </tr>
                {otherPassenger.length > 0 &&
                  otherPassenger.map((item, index) => (
                    <tr key={index}>
                      <td>Passenger {index + 2}</td>
                      <td>{item.title + ' ' + item.name}</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>{item.nationality}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div class='transport-details mt-3'>
          <h4>TRANSPORT DETAILS</h4>
          <table class='table table-bordered'>
            <thead>
              <tr>
                <th>Sector</th>
                <th>Transport</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JED-MAK-MED-MED APT</td>
                <td>Ziarat: None</td>
              </tr>
              <tr>
                <td>JED-MAK</td>
                <td>BUS</td>
              </tr>
              <tr>
                <td>MAK-MED</td>
                <td>BUS</td>
              </tr>
              <tr>
                <td>MED-MED APT</td>
                <td>BUS</td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <div className='text-center print-div-hide justify-content-center d-flex  ms-2'>
          <button
            onClick={toggleModal}
            className='btn mb-3 me-2 select-styling search-btn1 set-page-ntm-width detail-view-btn  btn-success'
          >
            Cancellation
          </button>
          <button
            onClick={printinvoice}
            className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'
          >
            Print Invoice
          </button>
        </div>
        <div className='row'>
        {/* <div class=' col-lg-12 mt-3'>
          <p>MAKKAH OFFICE | SOHAIL NAWAZ SADOZAI | PHONE #: 058-3390811</p>
          <p>MAKKAH OFFICE | OWAIS BHUTTA | PHONE #: 059-8382599</p>
          <p>MADINA OFFICE | KOUSAR NADEEM | PHONE #: 059-1139698</p>
          <p>MADINA OFFICE | RIZWAN MED TRANSPORT | PHONE #: 0599104921</p>
          <p>
            JEDDAH AIRPORT OFFICE | TRANSPORT BY NAQAL ARABI AND WAFA AL MASSI |
            PHONE #: 05
          </p>
        </div> */}
        {/* <div class=' text-end col-lg-6 mt-3'>
          <h6>:نوٹ</h6>
          <p> براہ کرم اس بات کا خاص خیال رکھیں کہ روانگی سے کم از کم تین گھنٹے پہلے ائرپورٹ پہنچیں تاکہ آپ کی روانگی کے وقت آپ کو کسی قسم کی پریشانی کا سامنا نہ کرنا پڑے۔ علاوہ ازیں، تمام سفراء کو اطلاع دی جاتی ہے کہ وہ اپنے سفری دستاویزات اور شناختی کارڈز کو ہمراہ رکھیں۔</p>
        </div> */}
        </div>
      </div>
    </>
  )
}
export default HotelInvoice
