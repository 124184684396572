import React,{useState,useEffect} from 'react'
import hotelImg from '../../Images/Packages/hotel-img.jpg'
import tqwa from '../../Images/Home/taqwa.jpg'
import masher from '../../Images/Home/masher.jpg'
import elafF from '../../Images/Packages/elaf-f.jpg'
import offers from '../../Images/Packages/offers.jpg'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadset,
  faPersonWalkingLuggage,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Hotelapitoken,ApiEndPoint } from '../GlobalData/GlobalData'
import { Button } from 'reactstrap'
function MorePackages () {
  const [t,i18n] = useTranslation();
  const language=i18n.language;
  const [packages,setPackages]=useState([]);
  useEffect(()=>{
    GetPackages();
  },[]);

  const GetPackages = async () => {
    var token = Hotelapitoken();
    var apiendpoint=ApiEndPoint();
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      var packagesCount;
      if (response.data.latest_packages.length !== 0) {
        var umrah = response.data.latest_packages[0].length
        var alaqsa = response.data.latest_packages[1].length
        var turkey = response.data.latest_packages[2].length
        packagesCount = {
          umrahs: umrah,
          alaqsas: alaqsa,
          turkeys: turkey
        }
      }
      setPackages(packagesCount);
    
    } catch (error) {
      console.error('Error:', error)
    }
  };

  return (
    <>
     <div class="container mt-5 mb-5">
        <div class="row align-items-center justify-content-between g-4">

          <div class=" col-md-4 col-sm-6">
            <div className="hotel-home text-center">
              <img className=' w-100' src={tqwa } alt='hotel-imgs' />
              <h3>Elaf Al Taqwa Hotel</h3>
              <p>Elaf Al-Taqwa Hotel offers a unique spiritual experience, with its distinctive panoramic view of the Prophet's Mosque and its proximity to many holy destinations, including Al-Ghamama Mosque, Abu Bakr Al-Siddiq Mosque</p>
                <h2 className='mt-5'>Rate From</h2>
                <h2>450.00 SAR</h2>
                <Button className='book-btn-hotel'>BOOK NOW</Button>
            </div>
          </div>
          <div class=" col-md-4 col-sm-6">
            <div className="hotel-home text-center">
              <img className=' w-100' src={masher } alt='hotel-imgs' />
              <h3>Elaf Al Mashaer Hotel</h3>
              <p>The Elaf Al Mashaer is located only 400 meters from the Holy Mosque with easy access to the King Abdulaziz and King Fahad Gates. The hotel radiates Makkah’s serenity as guests listen to the tuneful sound of the Muezzin.</p>
                <h2 className='mt-5'>Rate From</h2>
                <h2>450.00 SAR</h2>
                <Button className='book-btn-hotel'>BOOK NOW</Button>
            </div>
          </div>

          <div class=" col-md-4 col-sm-6">
            <div className="hotel-home text-center">
              <img className=' w-100' src={hotelImg } alt='hotel-imgs' />
              <h3>Elaf Bakkah</h3>
              <p>
The hotel has 810 guest rooms and suites. Its 390 interconnecting rooms are ideal for larger families and groups. A further 16 rooms cater to guests with special needs and wheelchair service can be provided</p>
                <h2 className='mt-5'>Rate From</h2>
                <h2>450.00 SAR</h2>
                <Button className='book-btn-hotel'>BOOK NOW</Button>
            </div>
          </div>

        

        </div>

      </div>
      <section className='offer-section'>
        <div className='row align-items-center mt-5 gx-0' >
        <div className='col-sm-6'>
        <img className=' w-100' src={offers} alt='offers-imgs' />
       
        </div>
        <div className='col-sm-6'>
          <div className='ms-5'>
          <h3>SPECIAL OFFERS</h3>
          <p>Get the most out of your stay by exploring special rates and packages at our hotel.</p>
          <Button className='book-btn-hotel'>
                explore our offers
                            </Button>
          </div>
        </div>
        </div>
        <div className='row align-items-center  gx-0'style={{backgroundColor:'#C6AA77'}} >
        <div className='col-sm-6' >
          <div className='ms-5' >
          <h3>ELAF FIRST</h3>
          <p>At ELAF FIRST, you are our world. Everything starts with you. You come first.
          Welcome to ELAF FIRST. Immerse yourself in a world of exclusive benefits and privileges, specifically designed to cater to your individual needs and experiences.</p>
          <Button className='book-btn-hotel'>
               Join Now
                            </Button>
          </div>
        </div>
        <div className='col-sm-6'>
        <img className=' w-100' src={elafF} alt='offers-imgs' />
       
        </div>
        </div>

      </section>
     
    </>
  )
}

export default MorePackages
