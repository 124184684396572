import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHandPointRight, faLocation } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import { Panel, PanelGroup } from 'rsuite';
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import OwlCarousel from 'react-owl-carousel';
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function HotelDetail () {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  var endpoint = ApiEndPoint()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [counter, setCounter] = useState(0)

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])

  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  // console.log(hoteldetail)
  const seleectedhotelfromlist = useSelector(
    state => state.hotels.hotels.hotels_list[index]
  )
  let { id } = useParams()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }

  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {

  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()

    const selectedroom = hoteldetail.rooms_options[index]
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].adult === selectedroom.adults &&
        result[i].child === selectedroom.childs &&
        result[i].qty === selectedroom.rooms_qty
      ) {
        if (result[i].status === false) {
          const key = { rateKey: selectedroom.booking_req_id, index: index }
          const newSelectedRoom = {
            room_rate_key: JSON.stringify(key),
            rooms_qty: selectedroom.rooms_qty,
            index: index
          }
          result[i].status = true
          setSelectedRooms([...selectedRooms, newSelectedRoom])
          setHotelSelectedRooms([...hotelSelectedRooms, key])
        } else {
          if (selectedRooms.some(room => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
              room => room.index !== index
            )
            const newroom = hotelSelectedRooms.filter(
              item => item.index !== index
            )
            setSelectedRooms(updatedSelectedRooms)
            setHotelSelectedRooms(newroom)
            result[i].status = false
          } else {
            toast.error('Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }
      }
    }
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        )
        return
      }

      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }
      const finaldata = {
        token:
          'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )
        // Handle the API response here
        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if (response.data !== '') {
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const renderPrice = price => {
    const userData = localStorage.getItem('HotelCurrency')
    const Rates = JSON.parse(userData)
    if (userData !== null) {
      if (CurrencyRates === undefined) {
        const gbpprice = Rates.conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = Rates.conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      return baseprice.toFixed(0)
    } else {
      setShowPrice(false)
    }
  }
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      return finalpricemarkup.toFixed(2)
    }
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div>
            <ToastContainer />
            <div className='cities-top'>
        <div className="container">
        <h3>Hotel Detail</h3>
        {/* <h4>RABIAN HOSPITALITY IS WORLD-RENOWNED BUT RARELY DEFINED.</h4>
        <p>Our traditional hospitality differs from other cultures in that it expects nothing in return.</p> */}
       
      </div>
      </div>
            <div className='container '>
              <div className='m-2 p-3  modify-flight-search'>
                <HotelSearch />
              </div>
            </div>

            <nav className='navbar view-detail-navbar'>
              <div className='container'>
                <ul>
                  <li>
                    <a
                      href='#section-1'
                      onClick={() => handleButtonClick2('section-1')}
                      className={activeButton === 'section-1' ? 'current' : ''}
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-2'
                      onClick={() => handleButtonClick2('section-2')}
                      className={activeButton === 'section-2' ? 'current' : ''}
                    >
                      Rooms
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-3'
                      onClick={() => handleButtonClick('section-3')}
                      className={activeButton === 'section-3' ? 'current' : ''}
                    >
                      Facilities
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-4'
                      onClick={() => handleButtonClick('section-4')}
                      className={activeButton === 'section-4' ? 'current' : ''}
                    >
                      Near By Place
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-5'
                      onClick={() => handleButtonClick('section-5')}
                      className={activeButton === 'section-5' ? 'current' : ''}
                    >
                      Location
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-6'
                      onClick={() => handleButtonClick('section-6')}
                      className={activeButton === 'section-6' ? 'current' : ''}
                    >
                      Review & Rating
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className='container mt-2'>
              <section id='section-1' className='pt-2'>
                <h3>{hoteldetail.hotel_name}</h3>
                <div className='row'>
                  <div>
                    <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                      <FontAwesomeIcon icon={faLocation} />
                    </i>{' '}
                    {hoteldetail.hotel_address}
                  </div>
                </div>
                {/* <Images data={hoteldetail.hotel_gallery} /> */}
                <OwlCarousel
      className="owl-theme"
      loop
      margin={10}
      autoplay
      autoplaySpeed='1000'
      responsive={{
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 4
        }
      }}
      nav
    >
      {hoteldetail.hotel_gallery.map((item,index)=>(
         <div key={index} className="item"><img height='200' width='200' src={item}/></div>
      ))}
    </OwlCarousel>
                {/* <div className='row hotel-dec mt-2 mb-2 p-2'>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Hotel Type
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Minimum Stay
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        >
                          {daysBetween} Night
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Check In
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        >
                          {moment(ReduxSearchData.check_in).format(
                            'DD-MM-YYYY'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Check Out
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        >
                          {moment(ReduxSearchData.check_out).format(
                            'DD-MM-YYYY'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Country
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        >
                          {hoteldetail.hotel_country}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          City
                        </p>
                        <p
                          className='mt-0'
                          style={{ fontSize: '12px' }}
                          class='title '
                        >
                          {hoteldetail.hotel_city}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Boards
                        </p>
                        {hoteldetail.hotel_boards.map((item, index) => (
                          <span
                            key={index}
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {item.board_name}{' '}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className=' col-sm-3 col-6 col-md-3 col-lg-3 mt-1'>
                    <div class='single-tour-feature d-flex align-items-center mb-3'>
                      <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                        <i class='fas fa-check'>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                      </div>
                      <div class='single-feature-titles'>
                        <p style={{ fontSize: '13px' }} class='title fw-bold'>
                          Segments
                        </p>
                        {hoteldetail.hotel_segments.map((item, index) => (
                          <span
                            key={index}
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {item}{' '}
                          </span>
                        ))}{' '}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className='row '>
                  <div className='hotel-dec p-3'>
                    <h3 className='mt-2'>{hoteldetail.hotel_name}</h3>
                    {hoteldetail.hotel_provider === 'tbo' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: hoteldetail.description
                        }}
                      />
                    ) : (
                      <p className='mt-2 text-justify'>
                        {hoteldetail.description}{' '}
                      </p>
                    )}
                  </div>
                </div> */}
              </section>

              <section id='section-2' className='mt-2'>
                    <div className='d-flex mt-3 mb-3 justify-content-center align-items-center room-sdcgf' >
                  <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="injected-svg Icon" width="26" height="25" focusable="false" role="none" aria-hidden="true" viewBox="0 0 26 25" data-src="./assets/images/icons/rooms.svg">
                                        <path class="Icon-property--fill" fill="#000" d="M19.173 25H1c-.276 0-.5-.223-.5-.5V12c0-.197.115-.375.293-.456l11.88-5.4c.116-.053.247-.06.37-.017l6.294 2.18c.2.07.336.258.336.47v15.724c0 .277-.223.5-.5.5zM1.5 24h17.173V9.136l-5.77-1.997L1.5 12.32V24z"></path>
                                        <path class="Icon-property--fill" fill="#000" d="M12.85 25c-.278 0-.5-.223-.5-.5V7.04c0-.276.222-.5.5-.5.275 0 .5.224.5.5V24.5c0 .277-.225.5-.5.5M2.8 14.59c-.212 0-.41-.138-.477-.35-.083-.264.064-.545.327-.63l7.41-2.332c.265-.08.546.064.63.326.082.264-.065.544-.328.627l-7.41 2.334c-.052.017-.102.025-.152.025M2.8 17.323c-.232 0-.44-.162-.49-.397-.056-.27.117-.535.388-.592l7.41-1.557c.268-.062.536.117.593.387.057.27-.115.535-.386.592l-7.41 1.557c-.035.007-.07.01-.104.01M2.8 20.06c-.253 0-.47-.192-.497-.45-.03-.274.17-.52.445-.55l7.41-.778c.274-.033.522.17.55.445.03.274-.17.52-.444.55l-7.41.778c-.02.002-.037.004-.054.004M10.21 22.79H2.8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7.41c.278 0 .5.226.5.5s-.222.5-.5.5"></path>
                                        <path class="Icon-property--fill" fill="#000" d="M25 25h-7.968c-.276 0-.5-.223-.5-.5s.224-.5.5-.5H24.5V3.386l-7.16-2.35-6.754 3.007v3.5c0 .277-.223.5-.5.5-.276 0-.5-.223-.5-.5V3.72c0-.198.117-.376.297-.457L17.108.045c.113-.05.242-.06.36-.02l7.688 2.523c.205.067.344.26.344.475v21.48c0 .275-.225.5-.5.5"></path>
                                        <path class="Icon-property--fill" fill="#000" d="M21.852 7.1c-.277 0-.5-.224-.5-.5V4.556c0-.277.223-.5.5-.5.275 0 .5.223.5.5V6.6c0 .276-.225.5-.5.5M21.852 10.77c-.277 0-.5-.224-.5-.5V8.225c0-.277.223-.5.5-.5.275 0 .5.223.5.5v2.043c0 .276-.225.5-.5.5M21.852 14.44c-.277 0-.5-.226-.5-.5v-2.046c0-.276.223-.5.5-.5.275 0 .5.224.5.5v2.045c0 .275-.225.5-.5.5M21.852 18.107c-.277 0-.5-.223-.5-.5v-2.043c0-.276.223-.5.5-.5.275 0 .5.224.5.5v2.043c0 .278-.225.5-.5.5M21.852 21.777c-.277 0-.5-.223-.5-.5v-2.045c0-.275.223-.5.5-.5.275 0 .5.225.5.5v2.045c0 .278-.225.5-.5.5"></path>
                                    </svg>
                                </div>
                    <h4 className='ms-2'>Rooms</h4>
                      </div>
                <div className='row'>
                  <div className='col-md-10 col-sm-10'>
                    {/* <div className='row room-heading mb-2'>
                      <div className='col-md-4'>
                        <h5 className='tc fw-bold'>Room Type</h5>
                      </div>
                      <div className='col-md-8'>
                        <h5 className='tc fw-bold'>Room Detail</h5>
                      </div>
                    </div> */}
                    {hoteldetail.rooms_options.map((item, index) => (
                      <div
                        key={index}
                        className='row hotel-detail-border pt-2 pb-2'
                      >
                        <div className='col-md-4'>
                            {item.rooms_images &&
                            item.rooms_images.length > 0 ? (
                              <>
                              {item?.RoomPromotion && item.RoomPromotion !== '' ? (
                                 <div className='Panel'>
                                 <img
                                   src={item.rooms_images[0]}
                                   alt={item.room_name}
                                 />
                                   <div className='corner-ribbon'>
                                     <span className='cr-inner'>
                                       <span className='cr-text'>
                                         <strong>{item.RoomPromotion}</strong>
                                       </span>
                                     </span>
                                   </div>
                               </div>
                              ):(
                                <img
                                src={item.rooms_images[0]}
                                alt={item.room_name}
                              />
                              )}
                              </>
                            ) : (
                              <>
                              {item?.RoomPromotion && item.RoomPromotion !== '' ? (
                                 <div className='Panel'>
                                   <img src={noImage} alt='Default Image' />
                                    <div className='corner-ribbon'>
                                     <span className='cr-inner'>
                                       <span className='cr-text'>
                                         Sale{' '}
                                         <strong>{item.RoomPromotion}</strong>
                                       </span>
                                     </span>
                                   </div>
                               </div>
                              ):(
                                <img src={noImage} alt='Default Image' />

                              )}
                              </>                            )}
                        </div>
                        <div className='col-md-8 '>
                        <h5 className='mt-2'>{item.room_name}</h5>
                          {item.request_type !== '' && (
                            <div className='room-request mt-2'>
                              <h6>Room on Request</h6>
                            </div>
                          )}
                          <div className='item-from'>
                            <div className='pt-2 pb-2'>
                              <div className='room-detail-center mb-3 date-flex'>
                                <h6 className='tc'>Meal Type</h6>
                                <h6>{item.board_id}</h6>
                                {/* <h6 style={{ color: 'red' }}>Non Refundable</h6> */}
                              </div>
                              <div className=' date-flex mt-2 mb-3'>
                                <h6 className='tc'>Room Capacity</h6>
                                <h6>
                                  {' '}
                                  {item.adults} Adults , {item.childs} children ,{item.rooms_qty} Rooms
                                </h6>
                              </div>
                              <ul>
                            {item.rooms_facilities &&
                            item.rooms_facilities.length > 0 ? (
                              <RoomFacilities item={item} />
                            ) : (
                              <li>No facilities available</li>
                            )}
                          </ul>
                          <div>
                          <PanelGroup accordion bordered>
                            <Panel header="Cancellation policy?" >
                            <div className='d-flex justify-content-center'>
                                {item.cancliation_policy_arr &&
                                item.cancliation_policy_arr.length > 0 ? (
                                  new Date( item.cancliation_policy_arr[0].from_date) > todayDate ? (
                                    <h6>
                                      <span style={{ color: 'green' }}>
                                        RISK FREE!
                                      </span>{' '}
                                      No cancellation fee before{' '}
                                      {moment(
                                        item.cancliation_policy_arr[0].from_date
                                      ).format('DD-MM-YYYY')}{' '}
                                      (property local time)
                                    </h6>
                                  ) : (
                                    <h6 style={{ color: 'red' }}>
                                      Non Refundable 
                                    </h6>
                                  )
                                ) : (
                                  <h6 style={{ color: 'red' }}>
                                    Non Refundable
                                  </h6>
                                )}
                              </div>
                            </Panel>
                           
                            
                          </PanelGroup>
                          </div>
                              <div className='text-center card-price-section mt-3 d-flex justify-content-end'
                                style={{ alignItems: 'center' }}
                              >
                                <div className='room-price-ab'>
                                  <div className='price text-center p-card-price'>
                                    {showPrice ? (
                                      <h6>
                                        {CurrencyRates === undefined
                                          ? baseCName
                                          : CurrencyRates.selectedcurrency}{' '}
                                        {renderPrice(
                                          calculateMarkup(item.rooms_total_price)
                                        )}
                                      </h6>
                                    ) : (
                                      <h6>
                                        {hoteldetail.hotel_curreny}{' '}
                                        {calculateMarkup(item.rooms_total_price)}
                                      </h6>
                                    )}
                                    
                                  </div>
                                  {/* <p className='mt-1' style={{fontSize:'12px'}}>
                                    {' '}
                                    Price For {daysBetween} Nights
                                  </p> */}
                                </div>
                                <div class='form-group' data-toggle='buttons'>
                                  <label
                                    onClick={event =>
                                      selectedRoomData(index, event)
                                    }
                                    class='btn btn-default select-room--checkbox primary'
                                  >
                                    <i class='fa fa-fw'></i>
                                    <input
                                      id={index}
                                      autoComplete='off'
                                      className='room-check'
                                      type='checkbox'
                                      checked={selectedRooms.some(
                                        room => room.index === index
                                      )}
                                      onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                    />
                                    Select Room
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className='row'>
                              <div className='d-flex justify-content-center'>
                                {item.cancliation_policy_arr &&
                                item.cancliation_policy_arr.length > 0 ? (
                                  new Date( item.cancliation_policy_arr[0].from_date) > todayDate ? (
                                    <h6>
                                      <span style={{ color: 'green' }}>
                                        RISK FREE!
                                      </span>{' '}
                                      No cancellation fee before{' '}
                                      {moment(
                                        item.cancliation_policy_arr[0].from_date
                                      ).format('DD-MM-YYYY')}{' '}
                                      (property local time)
                                    </h6>
                                  ) : (
                                    <h6 style={{ color: 'red' }}>
                                      Non Refundable 
                                    </h6>
                                  )
                                ) : (
                                  <h6 style={{ color: 'red' }}>
                                    Non Refundable
                                  </h6>
                                )}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='col-md-2 col-sm-2'>
                    <div className='theiaStickySidebar'>
                      <div className='book-now button-4 cart-btn'>
                        <button
                        style={{height:'3em'}}
                          className='btn mt-1 btn-primary  btn-block select-styling search-btn1 '
                          onClick={BookRoom}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id='section-3'>
                <div class='property-highlights'>
                  <h3>Property highlights</h3>
                  <div class='property-highlights__content'>
                    <div class='row'>
                      {hoteldetail.hotel_facilities === null ||
                      hoteldetail.hotel_facilities === '' ||
                      hoteldetail.hotel_facilities.length === 0 ? (
                        <h5> No Facilities Available</h5>
                      ) : (
                        <>
                          {hoteldetail.hotel_facilities.map((item, index) => (
                            <div key={index} class='col-md-3 col-6'>
                              <div class='item'>
                                <i
                                  style={{ color: '#d39d00' }}
                                  class='awe-icon fa fa-check'
                                  aria-hidden='true'
                                >
                                  <FontAwesomeIcon icon={faHandPointRight} />
                                </i>{' '}
                                <span>{item}</span>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <section id='section-4'>
                <h5>Near By Places</h5>
                <div class='row'></div>
              </section>
              <section id='section-5'>
                <h5 className='flight-heading mb-2'>Location</h5>
                <div class='col-12 col-md-12'>
                  <div style={{ width: '100%' }}>
                    <iframe
                      width='100%'
                      height='300'
                      frameborder='0'
                      scrolling='no'
                      marginheight='0'
                      marginwidth='0'
                      src={mapUrl}
                    ></iframe>
                  </div>
                </div>
              </section>
              <section id='section-7'>
                <h5 className='flight-heading mb-3'>Reviews</h5>
                <div id='reviews'>
                  <div class='rating-info'>
                    <div class='average-rating-review good'>
                      <span class='count'>7.5</span>
                      <em>Average rating</em>
                      <span>Good</span>
                    </div>
                    <ul class='rating-review'>
                      <li>
                        <em>Facility</em>
                        <span>7.5</span>
                      </li>
                      <li>
                        <em>Human</em>
                        <span>9.0</span>
                      </li>
                      <li>
                        <em>Service</em>
                        <span>9.5</span>
                      </li>
                      <li>
                        <em>Interesting</em>
                        <span>8.7</span>
                      </li>
                    </ul>
                    <button
                      className='btn btn-primary btn-block select-styling search-btn1'
                      style={{ width: 'unset', float: 'right' }}
                    >
                      Write a Review
                    </button>
                  </div>
                  <div id='comments'>
                    <ol class='commentlist'>
                      <li>
                        <div class='comment-box'>
                          {/* <div class="avatar">
                                                            <img src="images/img/demo-thumb.jpg" alt=""/>
                                                        </div> */}
                          <div class='comment-body'>
                            <p class='meta'>
                              <strong>Nguyen Gallahendahry</strong>
                              <span class='time'>December 10, 2012</span>
                            </p>
                            <div class='description'>
                              <p>
                                Takes me back to my youth. I love the design of
                                this soda machine. A bit pricy though..!
                              </p>
                            </div>

                            <div class='rating-info'>
                              <div class='average-rating-review good'>
                                <span class='count'>7.5</span>
                                <em>Average rating</em>
                                <span>Good</span>
                              </div>
                              <ul class='rating-review'>
                                <li>
                                  <em>Facility</em>
                                  <span>7.5</span>
                                </li>
                                <li>
                                  <em>Human</em>
                                  <span>9.0</span>
                                </li>
                                <li>
                                  <em>Service</em>
                                  <span>9.5</span>
                                </li>
                                <li>
                                  <em>Interesting</em>
                                  <span>8.7</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class='comment-box'>
                          <div class='avatar'>
                            <img src='images/img/demo-thumb.jpg' alt='' />
                          </div>
                          <div class='comment-body'>
                            <p class='meta'>
                              <strong>James Bond not 007</strong>
                              <span class='time'>December 10, 2012</span>
                            </p>
                            <div class='description'>
                              <p>
                                Takes me back to my youth. I love the design of
                                this soda machine. A bit pricy though..!
                              </p>
                            </div>

                            <div class='rating-info'>
                              <div class='average-rating-review good'>
                                <span class='count'>7.5</span>
                                <em>Average rating</em>
                                <span>Good</span>
                              </div>
                              <ul class='rating-review'>
                                <li>
                                  <em>Facility</em>
                                  <span>7.5</span>
                                </li>
                                <li>
                                  <em>Human</em>
                                  <span>9.0</span>
                                </li>
                                <li>
                                  <em>Service</em>
                                  <span>9.5</span>
                                </li>
                                <li>
                                  <em>Interesting</em>
                                  <span>8.7</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class='comment-box'>
                          <div class='avatar'>
                            <img src='images/img/demo-thumb.jpg' alt='' />
                          </div>
                          <div class='comment-body'>
                            <p class='meta'>
                              <strong>Bratt not Pitt</strong>
                              <span class='time'>December 10, 2012</span>
                            </p>
                            <div class='description'>
                              <p>
                                Takes me back to my youth. I love the design of
                                this soda machine. A bit pricy though..!
                              </p>
                            </div>

                            <div class='rating-info'>
                              <div class='average-rating-review fine'>
                                <span class='count'>5.0</span>
                                <em>Average rating</em>
                                <span>Fine</span>
                              </div>
                              <ul class='rating-review'>
                                <li>
                                  <em>Facility</em>
                                  <span>7.5</span>
                                </li>
                                <li>
                                  <em>Human</em>
                                  <span>9.0</span>
                                </li>
                                <li>
                                  <em>Service</em>
                                  <span>9.5</span>
                                </li>
                                <li>
                                  <em>Interesting</em>
                                  <span>8.7</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Layout>
      )}
      <div id='mybutton'>
        <button
          onClick={BookRoom}
          class='btn feedback  select-styling search-btn1 mt-2 detail-view-btn'
        >
          Book Now
        </button>
      </div>
    </>
  )
}

export default HotelDetail
