import React,{useEffect,useState} from 'react';
import Googlemap from '../../Components/Googlemap/Googlemap';
import img2 from '../../Images/Pages/contact.png';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';
import { ToastContainer,toast } from 'react-toastify';
import emailjs from 'emailjs-com';
const Contact = () => {
    const [formData,setFormData]=useState({email:'',name:'',subject:'',phnno:'',message:''});
    const [isBooking, setIsBooking] = useState(false);
    useEffect(()=>{
        new wow.WOW().init();
    },[]);
    
    const handleChange=(event)=>{
        const {value,name}=event.target;
        setFormData(prevdata=>({
            ...prevdata,
            [name]:value,
        }));
    };

    const isValidEmail=(email)=> {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const submitForm = async()=>{
        if(formData.name==='')
        {
            toast.info('Please Enter Your Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        } else if(formData.email===''){

            toast.info('Please Enter Your Email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.subject==='')
        {
            toast.info('Please Enter Subject.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.phnno==='')
        {
            toast.info('Please Enter Phone Number.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.message==='')
        {
            toast.info('Please Enter Message.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        else if(!isValidEmail(formData.email))
        {
            toast.info('Please Enter a valid email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }

        setIsBooking(true);
        try {
            const templateParams = {
              email: formData.email,
              name: formData.name,
              subject: formData.subject, 
              phone : formData.phnno,
              message: formData.message
            };
      
            await emailjs.send('service_d6zrc2l', 'template_la4ks0l', templateParams, 'c163bgNie5rW0iU1f');
            setIsBooking(false);
            toast.success('Email Send Successfully.', {
                position: toast.POSITION.TOP_RIGHT
              });
          } catch (error) {
            setIsBooking(false);
            console.error('Error sending email', error);
            toast.error('Error sending email.', {
                position: toast.POSITION.TOP_RIGHT
              });
          }
    }
    return (
        <>
        <Layout>
      
      <div className='cities-top'>
        <div className="container pt-5">
        <h3 className='pt-5'>CONTACT US</h3>
        <p>Feel free to contact us, we will be delighted to assist you with your inquiry.</p>
       
      </div>
      </div>
            <div className="col-12 mt-2 mb-5">
            
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="wow animate__animated animate__fadeInUp mb-5 mt-5 text-center" data-wow-duration="1s" data-wow-delay="0.2s">
                                    {/* <h1 className="text-capitalize fw-bold" style={{color:'#363d48'}}>Contact<span style={{ color: '#ffb606', fontWeight: 'bold' }} > Us</span></h1> */}
                                    {/* <p className="mt-4" style={{ fontSize: "18px" }}><b>Experience the power of a single partner for all your industrial supply chain and engineered solutions.</b></p> */}
                                    
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch wow animate__animated animate__fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="contact-wrap w-100  p-4">

                                            <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">You Name</label>
                                                            <input type="text" placeholder='Name' onChange={handleChange} className="form-control" name="name" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Email</label>
                                                            <input type="email" placeholder='Email' onChange={handleChange}  className="form-control" name="email"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Subject</label>
                                                            <input type="text" placeholder='Subject' onChange={handleChange}  className="form-control" name="subject"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Phone Number</label>
                                                            <input type="number" placeholder='Number' onChange={handleChange} className="form-control" name="phnno"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Message</label>
                                                            <textarea name="message" placeholder='Message'  className="form-control" onChange={handleChange} cols="30" rows="4" required></textarea>
                                                            <div className="mb-2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group p-3">
                                                                <button type="submit" disabled={isBooking} onClick={submitForm} className="btn btn-primary select-styling search-btn1"> {isBooking ? <Spinner /> : 'Submit'}</button>
                                                            </div>
                                                    </div>
                                                
                                                </div>
                                          
                                           
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5 d-flex align-items-stretch wow animate__animated animate__fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="info-wrap bg-primary w-100  p-4">
                                            <div className="mapviewport">
                                                <div id="mapgyver_holder"  >
                                                    <h4 className="h4-contact text-center mt-3"><span style={{ color: '#ffb606', fontWeight: 'bold' }} >Prestige Hotels</span></h4>
                                                    <div className="content-behind-map Contact_Us_Right_Div">
                                                        <h4 className="h4-contact h4-contact-gap"> Location</h4>
                                                        <p className="p-contact"> <strong className="strong_text"> Address:</strong> <b class="fa fa-map-marker" aria-hidden="true"> </b>4271 Zaid Bin Al Khattab, An Naim Dist. 23622 Jeddah, KSA<br /> <br />  </p>
                                                          <h4 className="h4-contact h4-contact-gap">Call Us:</h4>
                                                            
                                                        <p className="p-contact">  <strong className="strong_text"> Phone:</strong> <b className="fa fa-phone" aria-hidden="true"> </b>123 456 789</p>
                                                        <h4 className="h4-contact  h4-contact-gap"> Email</h4>
                                                       
                                                        <p className="p-contact mail" style={{ color: 'white' }}>info@prestige.com</p>
                                                        {/* <p className="p-contact mail"> <a href="mailto:hamza.shakeel@dynamissolutionz.com" style={{ color: 'white' }} >hamza.shakeel@dynamissolutionz.com</a></p> */}

                                                    </div>
                                                </div>
                                                {/* <div className="social-icons mt-5">
                                                    <a className="logo_hover" href="https://www.facebook.com/profile.php?id=100092518425376&is_tour_completed=true" target="_blank"> <img src="fb_logo.png" alt="Facebook Logo" width="38px" /></a>
                                                    <a className="logo_hover" href="https://instagram.com/decs324?igshid=ZGUzMzM3NWJiOQ==" target="_blank"> <img src="insta.png" alt="Insta Logo" height="50px" width="50px" /></a>
                                                    <a className="logo_hover" href="https://www.linkedin.com/company/dynamis-engineering-and-construction-solution/about/?viewAsMember=true" target="_blank"> <img src="linkedin_logo.png" alt="LinkedIn Logo" height="40px" width="40px" /></a>
                                                    <a className="logo_hover" href="https://api.whatsapp.com/send?phone=971542130049&text=decs" target="_blank"> <img src="whatsapp_logo.png" alt="Facebook Logo" width="38px" /></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Googlemap/> */}
            </Layout>
        </>
    );

}

const Spinner = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  };
export default Contact;