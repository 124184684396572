import React from 'react';
import gif from '../../Images/Loading/newloader.gif'
const Loading = ({status}) => {
  return (

    <div id="loading-wrapper" style={{zIndex:"9999"}}>
      <div id="loading-text">
              <img src={gif}/>
              {status && (
                  <div id="BrushCursor">
                  <div class="container">
                    <div class="p p1">We Are Searching for Vehicles</div>
                    <div class="p p2">We Are Searching for Vehicles</div>
                    <div class="p p3">
                    We Are Searching for Vehicles
                      <div class="cursor"></div>
                    </div>
                  </div>
                </div>
              )}
            
           </div>
          
    </div>

  );
};

export default Loading;
