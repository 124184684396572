import React from 'react';
import Layout from "../../Components/Layout/Layout";
import makkahbg from "../../Images/Cities/makkah/makkah-bg2.jpg"
import aisha from "../../Images/Cities/makkah/masjid-aisha.jpg"
import hira from "../../Images/Cities/makkah/cave-of-hira-entrance.jpg"
import museum from "../../Images/Cities/makkah/makkah-museum-1.jpg"



export default function makkah() {
  return (
    <>
      <Layout>
      <img src={makkahbg}/>
      <div className='cities-top'>
        <div className="container">
        <h3>Explore Places In Makkah</h3>
       
      </div>
      </div>
      <section className='city-places-section'>
        <div className="container ">
          <h2 className='my-5'>Explore Places In Makkah</h2>
          <div className="row">
            <div className="col-sm-6">
              <div className="city-col-top">
                <img src={aisha}/>
                <div className="city-col-content">
                  <h3>Masjid Aisha</h3>
                  <p>In Mecca, this is the second largest mosque. During Irham (sacred state), people come to this place. Umrah is renewed here by those performing their second Umrah. The mosque is named Masjid Aisha because Hazrat Aisha entered the state of Ihram here for Umrah. This is the place of Miqat - the principal boundary to be visited by Muslim pilgrims before performing Hajj or Umrah. In the state of Ihram. Visiting Masjid Aisha will offer a rich spiritual experience to your journey to Makkah.</p>
                  </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="city-col-top">
                <img src={hira}/>
                <div className="city-col-content">
                  <h3>The Cave Of Hira</h3>
                  <p>In this place, the Holy Prophet (SAW) meditated for 40 years. He received the first wahi (revelation) from Angel Jibreel here. During Ramadan in 610 CE, the Quran was revealed for the first time. "Night of power" became the name of this night. It is imperative that one visits the Cave of Hira to experience the strong and positive vibrations of the Prophet's meditations and revelations. As one of the many Ziyarat sites in Makkah, this is a significant one.</p>
                  </div>
              </div>
            </div>
          
            <div className="col-sm-6">
              <div className="city-col-top">
                <img src={museum}/>
                <div className="city-col-content">
                  <h3>Mecca Museum (Al-Zaher Palace Museum)</h3>
                  <p>The museum exhibits artifacts from different periods of Islamic history as well as the history of Makkah. A stone carving and cutwork design reflects its Islamic architectural style, and the museum is about 10-15 minute drive away from Al Haram. A museum contains history galleries, a gift shop, a library, an auditorium, a café, and a collection storage area.</p>
                  </div>
              </div>
            </div>
          </div>
        
        </div>
      </section>

      </Layout>
    </>
   
  )
}
