import React from 'react'
import Layout from "../../Components/Layout/Layout";
import medinaBg from "../../Images/Cities/Madina/medina-banner.jpg"
import prophetMosque from "../../Images/Cities/Madina/prophet-s-mosque-for-web.jpg"
import qiblatain from "../../Images/Cities/Madina/masjid-al-qiblatain.jpg"
import wafyapp from "../../Images/Cities/Madina/resizer-wafyapp.jpg"
import masajid from "../../Images/Cities/Madina/sab-u-masajid.jpg"

import baqi from "../../Images/Cities/Madina/Al-Baqi.jpg"
import uhud from "../../Images/Cities/Madina/Uhud.jpg"
import cemetery from "../../Images/Cities/Madina/martyrs-of-uhud-3.jpg"
import cave from "../../Images/Cities/Madina/cave-uhad.jpg"
import archers from "../../Images/Cities/Madina/archers.png"
import battle from "../../Images/Cities/Madina/battle-of-the-trench-site.jpg"
import hejaz from "../../Images/Cities/Madina/mouziam.jpg"
import darAlMuseum from "../../Images/Cities/Madina/dar-almadinah-museum.jpg"
import medinahMuseum from "../../Images/Cities/Madina/dar-al-madinah-museum.jpg"
export default function medinah() {
    return (
        <>
          <Layout>
          <img src={medinaBg}/>
          <div className='cities-top'>
            <div className="container">
            <h3>Explore Places In Medina</h3>
            <p>Officially known as Al Madinah h Al Munawwarah, the Enlightened City, Al Madinah is the second holiest city in Islam after Makkah. It is said that a larger portion of the Qur'an was revealed in Al Madinah. Al Madinah is known to have existed for over 1500 years before Prophet Muhammad (PBUH) migrates from Makkah and has seen numerous power exchanges within its comparatively short existence.</p>
            <p>The most famous sight in Al Madinah is undoubtedly the al-Masjid an-Nabawi also known as the Prophet’s Mosque, the final resting place of the Prophet Muhammad, while a plethora of markets, shopping malls, and restaurants line the city streets. Most tourism in Al Madinah comes from pilgrims who visit here as part of the Hajj and Umrah, an annual traditional journey that ends in Makkah. Let us help you with how you can explore this new region while you decide on an Umrah tour package.</p>
          </div>
          </div>
          <section className='city-places-section'>
            <div className="container ">
              <h2 className='my-5'>Famous Mosques To Visit In Al Madinah While You’re On An Umrah Tour:</h2>
              <div className="row">
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={prophetMosque}/>
                    <div className="city-col-content">
                      <h3>The Prophet's Mosque</h3>
                      <p>Known as Al-Masjid an-Nabawi, The Prophet's Mosque was built by Prophet Prophet Muhammad (PBUH) himself. It is prominent as the second holiest site in Islam after Masjid al-Haram in Makkah. The mosque built in 622 CE had then taken seven months to complete. It is a must-visit as per every Umrah guide.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={wafyapp}/>
                    <div className="city-col-content">
                      <h3>Quba Mosque</h3>
                      <p>Also known as Masjid Quba, the mosque is in the village of Quba and is approximately 6 kilometers away from Al Madinah city. It is said to be the first mosque built in Islam by Prophet Muhammed after his arrival in Al Madinah. A popular belief among Muslims is that performing one prayer at Quba Mosque is equal to one Umrah. It is a location you cannot miss and is included in every Umrah travel package.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={qiblatain}/>
                    <div className="city-col-content">
                      <h3>Masjid Al Qiblatain</h3>
                      <p>Literally translated as the Mosque of Two Qiblas is a mosque in Al Madinah. It is believed to be the place where Prophet Muhammad (PBUH) received the command to change the Qibla, the direction of prayer from Jerusalem towards the Kabah in Makkah. It is one of the few mosques to have two qiblas in different directions. If you are choosing a customized Umrah package, the Masjid Al Qiblatain must be on your list.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={masajid}/>
                    <div className="city-col-content">
                      <h3>The Seven Mosques</h3>
                      <p>Also known as Al-Masjid As-Sabah, it is a complex that houses six small historical mosques. The name 'The Seven Mosques’ was derived as it originally included the Masjid Al Qiblatayn as well. The complex houses Al-Fath Mosque, Salman Al-Farsi Mosque, Abu Bakr As-Siddiq Mosque, Umar bin Khattab Mosque, Ali bin Abi Talib Mosque and Fatimah Az-Zahra Mosque, with the Al-Fath being the largest of all. Each mosque is of historical significance in Islam.</p>
                      </div>
                  </div>
                </div>
              </div>
              <h2 className='my-5'>Famous Mosques To Visit In Al Madinah While You’re On An Umrah Tour:</h2>
              <div className="row">
              <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={baqi}/>
                    <div className="city-col-content">
                      <h3>Jannat Al-Baqi</h3>
                      <p>Also known as Baqi al-Gharqad, Jannat al-Baqi is the oldest and probably the first Islamic cemetery in Al Madinah. Located to the southeast of The Prophet's Mosque, Jannat al-Baqi is a resting place of some of Prophet Muhammad's (PBUH)family and friends.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={uhud}/>
                    <div className="city-col-content">
                      <h3>Mount Uhud</h3>
                      <p>Uhud mountain in Al Madinah is the site of one of the historical battles in the history of Islam. It houses several sites of importance like the Martyrs Cemetery, Cave of Uhud, and the Archers Mountain. This would be a great addition to your Umrah travel.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={cemetery}/>
                    <div className="city-col-content">
                      <h3>The Martyrs Of Uhud Cemetery</h3>
                      <p>The Martyrs Of Uhud Cemetry is located at the foot of Mount Uhud, which witnessed one of the most important battles in the history of Islam, known as the Battle of Uhud. The enclosure contains the bodies of 70 martyrs killed during the Battle of Uhud. The cemetery also houses the grave of Hamza ibn Abdul Muttalib.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={cave}/>
                    <div className="city-col-content">
                      <h3>Cave Of Uhud</h3>
                      <p>It is believed that during the Battle of Uhud, Prophet Muhammad (PBUH) had injured himself at the foothill of Mount Uhud. It is said that Talhah ibn Unaidillah carried the prophet on his back to the cave of Mount Uhud. A significant spot in the history of Al Madinah, this is a spot you should definitely include in your Umrah package for the family.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={archers}/>
                    <div className="city-col-content">
                      <h3>Archers Mountain</h3>
                      <p>Also known as Jabal Aynain/Jabal Ar-Rumat, the archer's mountain on Mount Uhud is the place where Prophet Muhammad (PBUH) had stationed his 50 companions during the Battle of Uhud. The place helped prevent enemies from attacking from behind.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={battle}/>
                    <div className="city-col-content">
                      <h3>Battle Of The Trench Site</h3>
                      <p>The Battle of the Trench, also known as Marakah al-Khandaq, was a 27 day-long standoff between Muslims in Al Madinah and Arab Jewish tribes. The confederate armies were estimated at around 10,000 men as compared to 3,000 Muslim defenders from Al Madinah. As a tactical measure, the Muslims dug a trench to render the confederate armies useless. In this battle of wits, the outnumbered Muslims led by Prophet Muhammad (PBUH) won, defeating the Arab and Jewish tribes from Makkah.</p>
                      </div>
                  </div>
                </div>
    
              </div>
              <h2 className='my-5'>Museums In Al Madinah</h2>
              <div className="row">
              <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={hejaz}/>
                    <div className="city-col-content">
                      <h3>Hejaz Railway Museum</h3>
                      <p>The Hejaz Railway Museum celebrates the legacy of the Hejaz Railway, a narrow-gauge railway that ran from Damascus to Al Madinah.</p>
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={darAlMuseum}/>
                    <div className="city-col-content">
                      <h3>Al-Madinah Museum</h3>
                      <p>The museum located in Al Madinah exhibits the heritage and history of the area and features archaeological collections, galleries, images, and rare artifacts.</p>
                      
                      </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="city-col-top">
                    <img src={medinahMuseum}/>
                    <div className="city-col-content">
                      <h3>Dar Al-Madinah Museum</h3>
                      <p>Apart from the aforementioned places, travelers and pilgrims can enjoy modern-day activities like shopping at malls like Al Noor and Rashed Mall and farmers' markets for dates. When explored on foot, one can enjoy small souvenir shops as well. One can also plan a one-day mazarat tour in Al Madinah</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    
          </Layout>
        </>
       
      )
}
